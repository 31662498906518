import React from "react";
import '../../sass/HeadImg.css';

const HeadImg = ({src}) => {
    return <img className="imgHead" src={src} alt="Header Img of subpage"/>
};


export default HeadImg;


