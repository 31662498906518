import React from "react";
import "../../sass/Pages.css";

import HeroHome from "../bigComponents/Hero-home";
import ProcedureView from "../bigComponents/Procedure-home";
import AboutUsHome from "../bigComponents/AboutUs-home";


const Home = () => {
    return (
        <div className="bodyView">
            <HeroHome/>
            <ProcedureView/>
            <AboutUsHome></AboutUsHome>
        </div>
    )
}

export default Home;