import React from "react";
import '../../sass/AboutUsSub.css';
import HeadImg from "../smallComponents/HeadImg";
import aboutUsImg from '../../img/subpagesHero/onas_01 (1)2 kopia.png';
import twoCarsImg from '../../img/twoCars.png';
import star from "../../img/star.svg";
import {useTranslation} from "react-i18next";

const AboutUs = () => {

    const {t} = useTranslation();

    return (
        <div>
            <HeadImg src={aboutUsImg}></HeadImg>
            <div className="aboutUsSubContainerBg">
                <div className="aboutUsSubContainer">
                    <h2 className="aboutUsH2">{t("AboutUsH2")}</h2>
                    <div className="aboutUsSubContainer-elements">
                        <div className="aboutUsContainer-elements__text">
                            <p>{t("AboutUsText1")}</p>
                            <p>{t("AboutUsText2")}</p>
                            <p className="lastP">{t("AboutUsText3")}</p>
                            <div className="procedureContainer-content__lines__bottom">
                                <div className="procedureContainer-content__lines__blue"></div>
                                <div className="procedureContainer-content__lines__red"></div>
                                <div className="procedureContainer-content__lines__blue"></div>
                            </div>
                        </div>
                        <div className="aboutUsContainer-elements__img">
                            <img src={twoCarsImg} alt="two cars render"/>
                        </div>
                    </div>

                </div>
            </div>
            <section className="aboutUsDescriptionContainer">
                <div className="aboutUsDescriptionContainer-reviews">
                    <div className="container-carousel-element odd">
                        <p>{t("CarouselReview1")}</p>
                        <div className="container-carousel-element-smallContainer">
                            <div className="container-carousel-element-smallContainer__name">
                                <div className="dot"></div>
                                <p className="authorName">Michał</p>
                            </div>
                            <div className="container-carousel-element-smallContainer__stars">
                                <img src={star} alt="rating star"/>
                                <img src={star} alt="rating star"/>
                                <img src={star} alt="rating star"/>
                                <img src={star} alt="rating star"/>
                                <img src={star} alt="rating star"/>
                            </div>
                        </div>
                    </div>
                    <div className="container-carousel-element second" >
                        <p>{t("CarouselReview2")}</p>
                        <div className="container-carousel-element-smallContainer">
                            <div className="container-carousel-element-smallContainer__name">
                                <div className="dot"></div>
                                <p className="authorName">Kolmax</p>
                            </div>
                            <div className="container-carousel-element-smallContainer__stars">
                                <img src={star} alt="rating star"/>
                                <img src={star} alt="rating star"/>
                                <img src={star} alt="rating star"/>
                                <img src={star} alt="rating star"/>
                                <img src={star} alt="rating star"/>
                            </div>
                        </div>
                    </div>
                    <div className="container-carousel-element">
                        <p>{t("CarouselReview3")}</p>
                        <div className="container-carousel-element-smallContainer">
                            <div className="container-carousel-element-smallContainer__name">
                                <div className="dot"></div>
                                <p className="authorName">Wojtek</p>
                            </div>
                            <div className="container-carousel-element-smallContainer__stars">
                                <img src={star} alt="rating star"/>
                                <img src={star} alt="rating star"/>
                                <img src={star} alt="rating star"/>
                                <img src={star} alt="rating star"/>
                                <img src={star} alt="rating star"/>
                            </div>
                        </div>
                    </div>
                    <div className="container-carousel-element odd forth">
                        <p>{t("CarouselReview6")}</p>
                        <div className="container-carousel-element-smallContainer">
                            <div className="container-carousel-element-smallContainer__name">
                                <div className="dot"></div>
                                <p className="authorName">Luki</p>
                            </div>
                            <div className="container-carousel-element-smallContainer__stars">
                                <img src={star} alt="rating star"/>
                                <img src={star} alt="rating star"/>
                                <img src={star} alt="rating star"/>
                                <img src={star} alt="rating star"/>
                                <img src={star} alt="rating star"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="aboutUsContainer-squares">
                    <div className="aboutUsContainer-squares__square">
                        <div className="aboutUsContainer-squares-textContainer">
                            <p className="aboutUsContainer-squares__mainText">1556</p>
                            <p className="aboutUsContainer-squares__text">{t("AboutUsHomeSquare1Text")}</p>
                        </div>
                    </div>
                    <div className="aboutUsContainer-squares__square">
                        <div className="aboutUsContainer-squares-textContainer">
                            <p className="aboutUsContainer-squares__mainText">62</p>
                            <p className="aboutUsContainer-squares__text">{t("AboutUsHomeSquare2Text")}</p>
                        </div>
                    </div>
                    <div className="aboutUsContainer-squares__square">
                        <div className="aboutUsContainer-squares-textContainer">
                            <p className="aboutUsContainer-squares__mainText">100%</p>
                            <p className="aboutUsContainer-squares__text">{t("AboutUsHomeSquare3Text")}</p>
                        </div>
                    </div>
                    <div className="aboutUsContainer-squares__square">
                        <div className="aboutUsContainer-squares-textContainer">
                            <p className="aboutUsContainer-squares__mainText">6</p>
                            <p className="aboutUsContainer-squares__text">{t("AboutUsHomeSquare4Text")}</p>
                        </div>
                    </div>
                </div>
                <div className="procedureContainer-content__lines__bottom">
                    <div className="procedureContainer-content__lines__blue"></div>
                    <div className="procedureContainer-content__lines__red"></div>
                    <div className="procedureContainer-content__lines__blue"></div>
                </div>
            </section>

        </div>
    );
};

export default AboutUs;
