import React from "react";
import Navbar from "./components/mainComponents/Navbar";
import Footer from "./components/mainComponents/Footer";
import Home from "./components/pages/Home";
import { Route, Routes } from "react-router-dom";
import Procedure from "./components/pages/Procedure";
import ScrollToTop from "./components/smallComponents/scrollToTop";
import Calculator from "./components/pages/Calculator";
import {ToastContainer} from "react-toastify";
import { Slide } from 'react-toastify';
import Realizations from "./components/pages/Realizations";
import AboutUs from "./components/pages/AboutUs";
import Contact from "./components/pages/Contact";
import Faq from "./components/pages/Faq";

function App() {


    const customToast = {
        '--toastify-color-error': '#E92428',
        '--toastify-color-success': '#1C3A66',
        '--toastify-toast-width': '420px',
        '--toastify-toast-min-height': '84px',
        fontSize: '1.8rem',
        // '--toastify-color-transparent': '#1C3A66'
        '--toastify-color-transparent': '#FFFFFF'

    }
  return (
      <div>
          <ScrollToTop/>
          <Navbar/>
          <ToastContainer style={customToast} transition={Slide}/>
          <div>
             <Routes>
                 <Route path='/' element={<Home/>}/>
                 <Route path='/procedura' element={<Procedure/>}/>
                 <Route path='/kalkulator' element={<Calculator/>}/>
                 <Route path='/realizacje' element={<Realizations/>}/>
                 <Route path='/o-nas' element={<AboutUs/>}/>
                 <Route path='/kontakt' element={<Contact/>}/>
                 <Route path='/faq' element={<Faq/>}/>
             </Routes>
          </div>
          <Footer/>
      </div>
  );
}

export default App;
