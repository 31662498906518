import React, {useEffect} from "react";
import '../../sass/Realizations.css';
import HeadImg from "../smallComponents/HeadImg";
import AOS from "aos";
import 'aos/dist/aos.css';

import realizationsImg from '../../img/subpagesHero/realizacje_01.png';
import car1 from '../../img/carImg/1998 BMW M ROADSTER.jpg';
import car2 from '../../img/carImg/2001 BMW M5.jpg';
import car3 from '../../img/carImg/2001 BMW 740 IL.jpg';
import car4 from '../../img/carImg/2008 BMW M5.jpg';
import car5 from '../../img/carImg/2011 CHEVROLET CAMARO.jpg';
import car6 from '../../img/carImg/2012 MERCEDES-BENZ C 63 AMG.jpg';
import car7 from '../../img/carImg/2013 JEEP WRANGLER.jpg';
import car8 from '../../img/carImg/2014 MERCEDES-BENZ S-CLASS S 550.jpg';
import car9 from '../../img/carImg/2014 YAMAHA BOAT.jpg';
import car10 from '../../img/carImg/2015 PORSCHE 911 TURBO.jpg';
import car11 from '../../img/carImg/2017 FORD FUSION TITANIUM HEV.jpg';
import car12 from '../../img/carImg/2019 VOLVO S60 T5 R-DESIGN.jpg';
import car13 from '../../img/carImg/2020 CHEVROLET CORVETTE.jpg';
import car14 from '../../img/carImg/2020 DODGE CHALLENGER RT SCAT PACK.jpg';
import car15 from '../../img/carImg/2020 RAM PROMASTER 3500.jpg';
import car16 from '../../img/carImg/2021 LAMBORGHINI URUS.jpg';
import car17 from '../../img/carImg/2021 RAM 1500 TRX.jpg';
import car18 from '../../img/carImg/2021 CHEVROLET CORVETTE 3LT.jpg';
import car19 from '../../img/carImg/2022 FORD EDGE TITANIUM.jpg';
import car20 from '../../img/carImg/2022 AUDI A5 PREMIUM PLUS.jpg';
import car21 from '../../img/carImg/2023 PORSCHE 911 CARRERA.png.jpg';
import {useTranslation} from "react-i18next";


const Realizations = () => {

    const {t} = useTranslation();

    function getOffsetForScreenSize() {
        const screenWidth = window.innerWidth;

        if(screenWidth <= 476) {
            return 200;
        } else if(screenWidth >= 477 && screenWidth <= 1200) {
            return 300;
        } else if (screenWidth >= 1201 && screenWidth <= 1919) {
            return 400;
        } else if (screenWidth === 1920) {
            return 300;
        } else {
            return 600;
        }
    }

    useEffect(() => {
        AOS.init({
            once: true,
            delay: 300,
            duration: 1500,
            offset: getOffsetForScreenSize(),
            // beforeReveal: (el) => {
            //     if (el.classList.contains("realizationsContainer-element")) {
            //         el.style.transition = "transform 0.5s";
            //     }
            // },
            // afterReveal: (el) => {
            //     if (el.classList.contains("realizationsContainer-element")) {
            //         el.style.transform = "scale(1.2)";
            //     }
            // }
        });
        AOS.refresh();
    }, []);


    return (
        <div>
            <HeadImg src={realizationsImg}></HeadImg>
            <div className="realizationsContainerBg">
                <div className="realizationsContainer">
                    <h2 className="realizationsH2">{t("RealizationsH2")}</h2>
                    <div className="realizationsContainer-elements">

                        <div className="zoom-wrapper">
                            <div className="scale-container">
                                <img src={car13} alt="Chevrolet Corvette"
                                     className="realizationsContainer-element aos-fade-up"
                                     data-aos="fade-up"/>
                            </div>
                        </div>

                        <div className="zoom-wrapper">
                            <div className="scale-container">
                                <img src={car20} alt="Audi A5 Premium Plus" className="realizationsContainer-element"
                                     data-aos="fade-up"/>
                            </div>
                        </div>

                        <div className="zoom-wrapper">
                            <div className="scale-container">
                                <img src={car19} alt="Ford Edge Titanium" className="realizationsContainer-element"
                                     data-aos="fade-up"/>
                            </div>
                        </div>

                        <div className="zoom-wrapper">
                            <div className="scale-container">
                                <img src={car16} alt="Lamborghini Urus" className="realizationsContainer-element"
                                     data-aos="fade-up"/>
                            </div>
                        </div>

                        <div className="zoom-wrapper">
                            <div className="scale-container">
                            <img src={car18} alt="Chevrolet Corvette 3LT" className="realizationsContainer-element"
                                 data-aos="fade-up"/>
                            </div>
                        </div>

                        <div className="zoom-wrapper">
                            <div className="scale-container">
                            <img src={car17} alt="Ram 1500 TRX" className="realizationsContainer-element"
                                 data-aos="fade-up"/>
                            </div>
                        </div>

                        <div className="zoom-wrapper">
                            <div className="scale-container">
                            <img src={car1} alt="BMW M5" className="realizationsContainer-element" data-aos="fade-up"/>
                            </div>
                        </div>

                        <div className="zoom-wrapper">
                            <div className="scale-container">
                            <img src={car21} alt="Porsche 911 carrera" className="realizationsContainer-element"
                                 data-aos="fade-up"/>
                            </div>
                        </div>

                        <div className="zoom-wrapper">
                            <div className="scale-container">
                            <img src={car15} alt="Ram promaster" className="realizationsContainer-element"
                                 data-aos="fade-up"/>
                            </div>
                        </div>

                        <div className="zoom-wrapper">
                            <div className="scale-container">
                            <img src={car2} alt="BMW M5" className="realizationsContainer-element" data-aos="fade-up"/>
                            </div>
                        </div>

                        <div className="zoom-wrapper">
                            <div className="scale-container">
                            <img src={car14} alt="Dodge Challenger RT SCAT PACK"
                                 className="realizationsContainer-element" data-aos="fade-up"/>
                            </div>
                        </div>

                        <div className="zoom-wrapper">
                            <div className="scale-container">
                            <img src={car3} alt="BMW 740 IL" className="realizationsContainer-element"
                                 data-aos="fade-up"/>
                            </div>
                        </div>

                        <div className="zoom-wrapper">
                            <div className="scale-container">
                            <img src={car4} alt="BMW M5" className="realizationsContainer-element" data-aos="fade-up"/>
                            </div>
                        </div>

                        <div className="zoom-wrapper">
                            <div className="scale-container">
                            <img src={car12} alt="Volvo S60 T5" className="realizationsContainer-element"
                                 data-aos="fade-up"/>
                            </div>
                        </div>

                        <div className="zoom-wrapper">
                            <div className="scale-container">
                            <img src={car5} alt="Chevrolet camaro" className="realizationsContainer-element"
                                 data-aos="fade-up"/>
                            </div>
                        </div>

                        <div className="zoom-wrapper">
                            <div className="scale-container">
                            <img src={car11} alt="Ford Fusion Titanium" className="realizationsContainer-element"
                                 data-aos="fade-up"/>
                            </div>
                        </div>

                        <div className="zoom-wrapper">
                            <div className="scale-container">
                            <img src={car6} alt="Mercedes C 63 AMG" className="realizationsContainer-element"
                                 data-aos="fade-up"/>
                            </div>
                        </div>

                        <div className="zoom-wrapper">
                            <div className="scale-container">
                            <img src={car7} alt="Jeep wrangler" className="realizationsContainer-element"
                                 data-aos="fade-up"/>
                            </div>
                        </div>

                        <div className="zoom-wrapper">
                            <div className="scale-container">
                            <img src={car10} alt="Porsche 911 Turbo" className="realizationsContainer-element"
                                 data-aos="fade-up"/>
                            </div>
                        </div>

                        <div className="zoom-wrapper">
                            <div className="scale-container">
                            <img src={car9} alt="Yamaha boat" className="realizationsContainer-element"
                                 data-aos="fade-up"/>
                            </div>
                        </div>

                        <div className="zoom-wrapper lastCarImg">
                            <div className="scale-container">
                            <img src={car8} alt="Mercedes S 550" className="realizationsContainer-element"
                                 data-aos="fade-up"/>
                            </div>
                        </div>
                    </div>
                    <div className="procedureContainer-content__lines__bottom">
                        <div className="procedureContainer-content__lines__blue"></div>
                        <div className="procedureContainer-content__lines__red"></div>
                        <div className="procedureContainer-content__lines__blue"></div>
                    </div>
                </div>

            </div>

        </div>
    );
};

export default Realizations;