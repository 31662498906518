import React, {useEffect} from "react";
import AOS from "aos";
import "../../sass/Pages.css";
import HeadImg from "../smallComponents/HeadImg";
import procedureImg from '../../img/subpagesHero/procedura_01.png'
import '../../sass/ProcedureSub.css';
import wyborSamochodu from '../../img/procedureIcons/wybór_samochodu.svg';
import platonscZaSamochod from '../../img/procedureIcons/płatność_za_samochód.svg';
import transport from '../../img/procedureIcons/transport.svg';
import odprawaCelna from '../../img/procedureIcons/odprawa_celna.svg';
import odbiorsamochodu from '../../img/procedureIcons/odbiór_samochodu.svg';
import umowaIKaucja from '../../img/procedureIcons/umowa_oraz_kaucja.svg';
import {useTranslation} from "react-i18next";


const Procedure = () => {

    const { t } = useTranslation();

    function getOffsetForScreenSize() {
        const screenWidth = window.innerWidth;

        if(screenWidth <= 476) {
            return 200;
        } else if(screenWidth >= 477 && screenWidth <= 1200) {
            return 300;
        } else if (screenWidth >= 1201 && screenWidth <= 1919) {
            return 400;
        } else if (screenWidth === 1920) {
            return 600;
        } else {
            return 800;
        }
    }

    useEffect(() => {
        AOS.init({
            once: true,
            delay: 300,
            duration: 1500,
            offset: getOffsetForScreenSize(),

        });
        AOS.refresh();
    }, []);
    return (
        <div>
            <HeadImg src={procedureImg}></HeadImg>
            <div className="procedureSubContainerBg">
                <div className="procedureSubContainer">
                    <h2 className="procedureH2">{t("ProceduraHomeH2")}</h2>
                    <div className="procedureSubContainer-elements">
                        <div className="procedureSubContainer-element aos-fade-up" data-aos="fade-up">
                            <img src={wyborSamochodu} alt="Car icon"/>
                            <div>
                                <h3 className="procedureSubContainer-element__title">01. {t("ProcedureHomeTitle1")}</h3>
                                <div className="procedureSubContainer-element__text">{t("ProcedureHomeDesc1")}
                                </div>
                            </div>
                        </div>
                        <div className="procedureSubContainer-element aos-fade-up" data-aos="fade-up">
                            <img src={umowaIKaucja} alt="Car icon"/>
                            <div>
                                <h3 className="procedureSubContainer-element__title">02. {t("ProcedureHomeTitle2")}</h3>
                                <div className="procedureSubContainer-element__text">{t("ProcedureHomeDesc2")}
                                </div>
                            </div>
                        </div>
                        <div className="procedureSubContainer-element aos-fade-up" data-aos="fade-up">
                            <img src={platonscZaSamochod} alt="Car icon"/>
                            <div>
                                <h3 className="procedureSubContainer-element__title">03. {t("ProcedureHomeTitle3")}</h3>
                                <div className="procedureSubContainer-element__text">{t("ProcedureHomeDesc3")}
                                </div>
                            </div>
                        </div>
                        <div className="procedureSubContainer-element aos-fade-up" data-aos="fade-up">
                            <img src={transport} alt="Car icon"/>
                            <div>
                                <h3 className="procedureSubContainer-element__title">04. {t("ProcedureHomeTitle4")}</h3>
                                <div className="procedureSubContainer-element__text">{t("ProcedureHomeDesc4")}
                                </div>
                            </div>
                        </div>
                        <div className="procedureSubContainer-element aos-fade-up" data-aos="fade-up">
                            <img src={odprawaCelna} alt="Car icon"/>
                            <div>
                                <h3 className="procedureSubContainer-element__title">05. {t("ProcedureHomeTitle5")}</h3>
                                <div className="procedureSubContainer-element__text">{t("ProcedureHomeDesc5")}
                                </div>
                            </div>
                        </div>
                        <div className="procedureSubContainer-element aos-fade-up" data-aos="fade-up">
                            <img src={odbiorsamochodu} alt="Car icon"/>
                            <div>
                                <h3 className="procedureSubContainer-element__title">06. {t("ProcedureHomeTitle6")}</h3>
                                <div className="procedureSubContainer-element__text">{t("ProcedureHomeDesc6")}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="procedureContainer-content__lines__bottom">
                        <div className="procedureContainer-content__lines__blue"></div>
                        <div className="procedureContainer-content__lines__red"></div>
                        <div className="procedureContainer-content__lines__blue"></div>
                    </div>

                </div>
            </div>
            <div className="youTubeContainerBg">
                <div className="youTubeContainer">

                    <h2>YouTube</h2>
                    <div className="youTubeContainer-movie">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/OoAPnFuyLI0"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></iframe>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/rTucB8xIzQ4"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></iframe>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/7hhlf3ZP_UI"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></iframe>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/YVshC_Sh7P4"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></iframe>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/Jda0FZdCMPg"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></iframe>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/FX7Il7z-Tx8"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></iframe>
                    </div>

                </div>
                <div className="procedureContainer-content__lines__bottom">
                    <div className="procedureContainer-content__lines__blue"></div>
                    <div className="procedureContainer-content__lines__red"></div>
                    <div className="procedureContainer-content__lines__blue"></div>
                </div>
            </div>
        </div>
    )
}

export default Procedure;