import React, {useEffect, useState} from "react";
import HeadImg from "../smallComponents/HeadImg";
import calculatorImg from '../../img/subpagesHero/kalkulator_01.png';
import '../../sass/CalculatorSub.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BetterButton from "../smallComponents/BetterButton";
import axios from "axios";
import {useTranslation} from "react-i18next";
// import Procedure from "./Procedure";
const Calculator = () => {

    const {t} = useTranslation()

    const[amount, setAmount] = useState(undefined);
    const[location, setLocation] = useState('');
    const[result, setResult] = useState('');
    const [exchangeRates, setExchangeRates] = useState({
        USD: 4.0,
        EUR: 4.0,
        GBP: 4.0,
    });

    useEffect(() => {
        const storedRates = JSON.parse(localStorage.getItem("exchangeRates"));
        if (storedRates) {
            setExchangeRates(storedRates);
        }

        const lastFetched = localStorage.getItem("lastFetched");
        const oneHour = 3600000;

        if (!lastFetched || Date.now() - lastFetched > oneHour) {
            fetchExchangeRates();
        }

        const interval = setInterval(fetchExchangeRates, oneHour);

        return () => clearInterval(interval);
    }, []);

    const fetchExchangeRates = async () => {
        try {
            const response = await axios.get(
                "https://api.exchangeratesapi.io/latest",
                {
                    params: {
                        base: "PLN",
                        access_key: "58a33c801ddccfd2967fc38155cb27ae",
                    },
                }
            );
            if (response?.data?.rates) {
                const newRates = response.data.rates;
                localStorage.setItem("exchangeRates", JSON.stringify(newRates));
                localStorage.setItem("lastFetched", Date.now());
                setExchangeRates(newRates);
            }
        } catch (error) {
            console.error("Error fetching exchange rates: ", error);
        }
    };

    const handleAmountChange = (event) => {
        const inputValue = event.target.value;
        const newAmount = inputValue !== '' ? Number(inputValue) : undefined;
        setAmount(newAmount);
        // setAmount(Number(event.target.value));
    }

    const handleLocationChange = (event) => {
        setLocation(event.target.value);
    }


    const displayErrorNotification = (errorMessage) => {
        toast.error(errorMessage, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };


    const handleCalculate = () => {

        if (!amount) {
            displayErrorNotification(t("emptyAmount"));
            return;
        }
        if (amount <= 0) {
            displayErrorNotification(t("amountBelowZero"));
            return;
        }
        if (location === '') {
            displayErrorNotification(t("emptyLocation"));
            return;
        } else {
            toast.success(t("success"), {
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                autoClose: 5000,
                delay: 0,
                theme: 'colored',
            });
        }
        const locationMap = {
            'ABILENE' : 1305,
            'ACE-CARSON' : 1750,
            'ACE-PERRIS' : 1750,
            'AKRON-CANTON' : 1705,
            'ALBUQUERQUE' : 1625,
            'ALTOONA' : 1355,
            'AMARILLO' : 1590,
            'ANAHEIM' : 1690,
            'ANDREWS' : 1555,
            'ANTELOPE' : 2075,
            'APPLETON' : 1290,
            'ASHEVILLE' : 1250,
            'ASHLAND' : 1655,
            'ATLANTA EAST' : 1020,
            'ATLANTA NORTH' : 1055,
            'ATLANTA SOUTH' : 1020,
            'ATLANTA WEST' : 1020,
            'AUSTIN' : 1220,
            'AVENEL NEW JERSEY' : 925,
            'BAKERSFIELD' : 1880,
            'BALTIMORE' : 975,
            'BATON ROUGE' : 1055,
            'BILLINGS' : 2075,
            'BIRMINGHAM' : 1105,
            'BOISE' : 2570,
            'BOSTON-SHIRLEY' : 1080,
            'BOWLING GREEN' : 1275,
            'BRIDGEPORT' : 950,
            'BUCKHANNON' : 1325,
            'BUFFALO' : 1180,
            'BURLINGTON' : 1155,
            'CALGARY AB' : 1155,
            'CANDIA' : 1105,
            'CARTERSVILLE' : 975,
            'CASPER' : 2025,
            'CENTRAL NEW JERSEY' : 840,
            'CHAMBERSBURG' : 1080,
            'CHARLESTON' : 925,
            'CHARLOTTE' : 1055,
            'CHATTANOOGA' : 1130,
            'CHICAGO NORTH' : 1125,
            'CHICAGO SOUTH' : 1125,
            'CHICAGO WEST' : 1125,
            'CHICOUTIMI QC' : 2150,
            'CHINA GROVE' : 1030,
            'CINCINATTI' : 1175,
            'CLEARWATER' : 1055,
            'CLEVELAND' : 1155,
            'COLORADO SPRINGS' : 1625,
            'COLTON' : 1740,
            'COLUMBIA' : 950,
            'COLUMBIA MO' : 1405,
            'COLUMBUS' : 1175,
            'CONCORD' : 1055,
            'CORPUS CHRISTI' : 1150,
            'COURTICE ON' : 1080,
            'CRASHEDTOYS ATLANTA' : 1150,
            'CRASHEDTOYS EAST BETHEL' : 1355,
            'CRASHEDTOYS MINNEAPOLIS' : 1405,
            'CRASHEDTOYS SACRAMENTO' : 1880,
            'CULPEPER' : 1155,
            'DALLAS' : 1150,
            'DANVILLE' : 1155,
            'DAVENPORT' : 1355,
            'DAYTON' : 1175,
            'DENVER' : 1625,
            'DES MOINES' : 1405,
            'DETROIT' : 1205,
            'DOTHAN' : 1055,
            'DUNDALK' : 1030,
            'EAST BAY' : 1880,
            'EDMONTON AB' : 2400,
            'EL PASO' : 1380,
            'ENGLISHTOWN' : 950,
            'ERIE' : 1250,
            'EUGENE' : 2100,
            'EXETER' : 1105,
            'FARGO' : 1845,
            'FAYETTEVILLE' : 1355,
            'FLINT' : 1275,
            'FONTANA' : 1720,
            'FORT MYERS' : 1080,
            'FORT PIERCE' : 1055,
            'FORT WAYNE' : 1125,
            'FORT WORTH NORTH' : 1175,
            'FREDERICKSBURG' : 1045,
            'FREMONT' : 1880,
            'FRESNO' : 1855,
            'FT. PIERCE' : 1050,
            'FT. WORTH' : 1150,
            'GLASSBORO EAST' : 865,
            'GRAHAM' : 2250,
            'GRAND RAPIDS' : 1325,
            'GREENSBORO' : 1055,
            'GREENVILLE' : 975,
            'GRENADA' : 1405,
            'GULF COAST' : 1480,
            'HALIFAX NS' : 2000,
            'HAMILTON ON' : 1080,
            'HAMMOND' : 1125,
            'HAMPTON' : 1155,
            'HARRISBURG' : 950,
            'HARTFORD' : 925,
            'HAYWARD' : 1880,
            'HAYWARD-DESERT' : 1855,
            'HELENA' : 2175,
            'HIGH DESERT' : 1750,
            'HONOLULU' : 4750,
            'HOUSTON' : 1015,
            'HUNTSVILLE' : 1080,
            'IA-CRASHEDTOYS ELDRIDGE' : 1405,
            'INDIANAPOLIS' : 1040,
            'IONIA' : 1405,
            'JACKSON' : 1380,
            'JACKSONVILLE' : 900,
            'KANSAS CITY' : 1475,
            'KINCHELOE' : 1745,
            'KITCHENER ON' : 1100,
            'KNOXVILLE' : 1180,
            'LAFAYETTE' : 1255,
            'LANSING' : 1325,
            'LAS VEGAS' : 1880,
            'LAUREL' : 1030,
            'LAVAL QC' : 1470,
            'LES CEDRES QC' : 1325,
            'LEXINGTON' : 1180,
            'LINCOLN' : 1500,
            'LITTLE ROCK' : 1355,
            'LITTLETON' : 1625,
            'LONDON ON' : 1130,
            'LONG BEACH' : 1720,
            'LONG ISLAND' : 925,
            'LONGVIEW' : 1175,
            'LOS ANGELES' : 1720,
            'LOUISVILLE' : 1180,
            'LUBBOCK' : 1380,
            'LUFKIN' : 1150,
            'LUMBERTON' : 1080,
            'LYMAN' : 1130,
            'MACON' : 950,
            'MADISON' : 1255,
            'MANCHESTER' : 1105,
            'MARTINEZ' : 1880,
            'MASCOUCHE QC' : 1450,
            'MCALLEN' : 1255,
            'MEBANE' : 1055,
            'MEMPHIS' : 1180,
            'METRO' : 1030,
            'MIAMI' : 1105,
            'MILTON ON' : 1080,
            'MILWAUKEE' : 1255,
            'MINNEAPOLIS' : 1405,
            'MISSOULA' : 2175,
            'MOBILE' : 1105,
            'MOCKSVILLE' : 1055,
            'MONCTON NB' : 1900,
            'MONTGOMERY' : 1055,
            'MONTREAL QC' : 1325,
            'MOOSE JAW SK' : 2250,
            'NASHVILLE' : 1130,
            'NEW CASTLE' : 975,
            'NEW ORLEANS' : 1255,
            'NEWBURGH' : 900,
            'NORTH BOSTON' : 1080,
            'NORTH CARLESTON' : 925,
            'NORTH HOLLYWOOD' : 1740,
            'NORTH SEATTLE' : 2290,
            'NORTHERN NEW JERSEY': 840,
            'NORTHERN VIRGINIA': 1155,
            'OKLAHOMA CITY': 1355,
            'OMAHA': 1625,
            'ORLANDO': 975,
            'OSHAWA ON': 1080,
            'OTTAWA ON': 1325,
            'PADUCAH': 1180,
            'PASCO': 2370,
            'PENSACOLA': 1105,
            'PEORIA': 1175,
            'PERIAN BASIN': 1380,
            'PHILADELPHIA': 900,
            'PHOENIX': 1880,
            'PITTSBURGH': 1155,
            'PORTAGE': 1305,
            'PORTLAND': 2125,
            'PRINCE ALBERT SK': 2550,
            'PROVIDENCE': 1105,
            'PULASKI': 1155,
            'PUNTA GORDA': 1105,
            'PUYALLUP': 2250,
            'QUEBEC CITY QC': 1690,
            'RALEIGH': 1030,
            'RANCHO CUCAMONGA': 1780,
            'REGINA SK': 2250,
            'RENO': 2030,
            'RICHMOND': 1055,
            'RIVERSIDE': 1665,
            'ROANOKE': 1155,
            'ROCHESTER': 1105,
            'ROSEDALE': 975,
            'SACRAMENTO': 1960,
            'SALT LAKE CITY': 2100,
            'SAN ANTONIO': 1150,
            'SAN BERNARDINO': 1730,
            'SAN DIEGO': 1800,
            'SAN JOSE': 1880,
            'SASKATOON SK': 2250,
            'SAVANNAH': 840,
            'SCRANTON': 975,
            'SEAFORD': 975,
            'SEATTLE': 2280,
            'SHADY SPRING': 1325,
            'SHREVERPORT': 1255,
            'SIKESTON': 1450,
            'SIOUX FALLS': 1695,
            'SO SACRAMENTO': 1880,
            'SOMERVILLE': 840,
            'SOUTH BEND': 1150,
            'SOUTH BOSTON': 1080,
            'SOUTHERN ILLINOIS': 1175,
            'SOUTHERN NEW JERSEY': 900,
            'SPARTANBURG': 975,
            'SPECIALTY DIVISION': 1125,
            'SPOKANE': 2370,
            'SPRINGFIELD': 1475,
            'ST. PHILIBERT QC': 1950,
            'ST. CLOUD': 1475,
            'ST. LOUIS': 1150,
            'STOUFFVILLE ON': 1100,
            'SUDBURY ON': 1500,
            'SUFFOLK': 1155,
            'SUN VALLEY': 1740,
            'SYRACUSE': 1055,
            'TALLAHASSEE': 1080,
            'TAMPA': 1055,
            'TANNER': 1080,
            'TAUNTON': 1080,
            'TEMPLETON': 1105,
            'TIDEWATER': 1155,
            'TIFTON': 950,
            'TORONTO ON': 1080,
            'TRENTON': 840,
            'TUCSON': 1955,
            'TULSA': 1355,
            'VALLEJO': 1880,
            'VAN NUYS': 1745,
            'VANCOUVER BC': 2900,
            'VANCOUVER ISLAND BC': 3350,
            'WACO': 1255,
            'WALTON': 1175,
            'WASHINGTON': 1030,
            'WEST PALM BEACH': 1105,
            'WEST WARREN': 1080,
            'WESTERN COLORADO': 1945,
            'WHEELING': 1125,
            'WICHITA': 1475,
            'WILMINGTON': 1055,
            'WINCHESTER ON': 1340,
            'WINNIPEG MB': 2250,
            'YORK HAVEN': 975,
            'YORK SPRINGS': 975,


        };

        //OPŁATA ZAKLADKA FEES
        let carFee;
        switch (true) {
            case amount <= 99.99:
                carFee = 1;
                break;
            case amount >= 100 && amount < 199.99:
                carFee = 25;
                break;
            case amount >= 200 && amount < 299.99:
                carFee = 60;
                break;
            case amount >= 300 && amount < 349.99:
                carFee = 80;
                break;
            case amount >= 350 && amount < 399.99:
                carFee = 90;
                break;
            case amount >= 400 && amount < 449.99:
                carFee = 130;
                break;
            case amount >= 500 && amount < 549.99:
                carFee = 140;
                break;
            case amount >= 550 && amount < 599.99:
                carFee = 150;
                break;
            case amount >= 600 && amount < 699.99:
                carFee = 165;
                break;
            case amount >= 700 && amount < 799.99:
                carFee = 185;
                break;
            case amount >= 800 && amount < 899.99:
                carFee = 200;
                break;
            case amount >= 900 && amount < 999.99:
                carFee = 215;
                break;
            case amount >= 1000 && amount < 1199.99:
                carFee = 230;
                break;
            case amount >= 1200 && amount < 1299.99:
                carFee = 255;
                break;
            case amount >= 1300 && amount < 1399.99:
                carFee = 275;
                break;
            case amount >= 1400 && amount < 1499.99:
                carFee = 280;
                break;
            case amount >= 1500 && amount < 1599.99:
                carFee = 290;
                break;
            case amount >= 1600 && amount < 1699.99:
                carFee = 305;
                break;
            case amount >= 1700 && amount < 1799.99:
                carFee = 315;
                break;
            case amount >= 1800 && amount < 1999.99:
                carFee = 325;
                break;
            case amount >= 2000 && amount < 2399.99:
                carFee = 355;
                break;
            case amount >= 2400 && amount < 2499.99:
                carFee = 380;
                break;
            case amount >= 2500 && amount < 2999.99:
                carFee = 400;
                break;
            case amount >= 3000 && amount < 3499.99:
                carFee = 450;
                break;
            case amount >= 3500 && amount < 3999.99:
                carFee = 500;
                break;
            case amount >= 4000 && amount < 4499.99:
                carFee = 600;
                break;
            case amount >= 4500 && amount < 4999.99:
                carFee = 625;
                break;
            case amount >= 5000 && amount < 5999.99:
                carFee = 655;
                break;
            case amount >= 6000 && amount < 6499.99:
                carFee = 675;
                break;
            case amount >= 6500 && amount < 6999.99:
                carFee = 675;
                break;
            case amount >= 7000 && amount < 7499.99:
                carFee = 700;
                break;
            case amount >= 7500 && amount < 7999.99:
                carFee = 700;
                break;
            case amount >= 8000 && amount < 8499.99:
                carFee = 725;
                break;
            case amount >= 8500 && amount < 8999.99:
                carFee = 725;
                break;
            case amount >= 9000 && amount < 9999.99:
                carFee = 725;
                break;
            case amount >= 10000 && amount < 10499.99:
                carFee = 750;
                break;
            case amount >= 10500 && amount < 10999.99:
                carFee = 750;
                break;
            case amount >= 11000 && amount < 11499.99:
                carFee = 750;
                break;
            case amount >= 11500 && amount < 11999.99:
                carFee = 760;
                break;
            case amount >= 12000 && amount < 12499.99:
                carFee = 775;
                break;
            case amount >= 12500 && amount < 14999.99:
                carFee = 790;
                break;
            case amount >= 15000:
                carFee = amount * 0.06 ;
                break;
            default:
                carFee = 0;
        }

        // OPŁATA ZAKŁADA FEES 2
        let carFeeSmall
        switch (true) {
            case amount <= 99.99:
                carFeeSmall = 0;
                break;
            case amount >= 100 && amount < 499.99:
                carFeeSmall = 49;
                break;
            case amount >= 500 && amount < 999.99:
                carFeeSmall = 59;
                break;
            case amount >= 1000 && amount < 1499.99:
                carFeeSmall = 79;
                break;
            case amount >= 1500 && amount < 1999.99:
                carFeeSmall = 89;
                break;
            case amount >= 2000 && amount < 3999.99:
                carFeeSmall = 99;
                break;
            case amount >= 4000 && amount < 5999.99:
                carFeeSmall = 109;
                break;
            case amount >= 6000 && amount < 7999.99:
                carFeeSmall = 139;
                break;
            case amount >= 8000:
                carFeeSmall = 149;
                break;
            default:
                carFeeSmall = 0;
        }
        // stała opłata 1
        const normalFeeOne = 79;
        const normalFeeTwo = 10;
        const broker = 300;
        const agency = 580;
        // const USD = 4.07;
        const USD = (1 / exchangeRates.USD).toFixed(3);
        const transportDE = 2200;
        const uscarsFee = 3100;

        let customsClearanceFee = (amount + carFee + carFeeSmall + normalFeeOne + normalFeeTwo) * 0.4 * 0.1;
        let valueAddedTax = (((amount + carFee + carFeeSmall + normalFeeOne + normalFeeTwo) * 0.4) + customsClearanceFee) * 0.21;

        const locationAmount = locationMap[location] || 0;
        let totalCost = (amount + carFee + carFeeSmall + normalFeeOne + normalFeeTwo + broker + locationAmount + customsClearanceFee + valueAddedTax + agency + 700) * USD + transportDE + uscarsFee;
        setResult('Szacowany koszt: '+Math.ceil(totalCost).toLocaleString() + ' PLN');
    }

    return (
        <div>
            <HeadImg src={calculatorImg}></HeadImg>
            <div className="calculatorSubContainerBg">
                <div className="calculatorSubContainer">
                    <h2 className="calculatorH2">{t("CalculatorH2")}</h2>
                    <div className="calculatorSubContainer-elements">
                        <div className="calculatorSubContainer-elements__text">
                            <p>{t("CalculatorText1")}</p>
                            <p>{t("CalculatorText2")}</p>

                            <h3>{t("CalculatorH3First")}</h3>
                            <table>
                                <thead>
                                <tr>
                                    <th>{t("CalculatorTable1")}</th>
                                    <th>{t("CalculatorTable2")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>USD</td>
                                    <td>{(1 / exchangeRates.USD).toFixed(3) + ' PLN'}</td>
                                </tr>
                                <tr>
                                    <td>EUR</td>
                                    <td>{(1 / exchangeRates.EUR).toFixed(3) + ' PLN'}</td>
                                </tr>
                                <tr>
                                    <td>GBP</td>
                                    <td>{(1 / exchangeRates.GBP).toFixed(3) + ' PLN'}</td>
                                </tr>
                                </tbody>
                            </table>

                        </div>
                        <div className="calculatorSubContainer-elements__calc">
                            <label>
                                {t("CalculatorCalc1")}
                                <input type="number" value={amount !==  undefined ? amount : ''} onChange={handleAmountChange} required/>
                            </label>
                            <label>
                                {t("CalculatorCalc2")}
                                <select value={location} onChange={handleLocationChange} required placeholder='Wybierz Lokalizacje'>
                                    <option value="">{t("CalculatorCalc3")}</option>
                                    <option value="ABILENE">ABILENE</option>
                                    <option value="ACE-CARSON">ACE-CARSON</option>
                                    <option value="ACE-PERRIS">ACE-PERRIS</option>
                                    <option value="AKRON-CANTON">AKRON-CANTON</option>
                                    <option value="ALBUQUERQUE">ALBUQUERQUE</option>
                                    <option value="ALTOONA">ALTOONA</option>
                                    <option value="AMARILLO">AMARILLO</option>
                                    <option value="ANAHEIM">ANAHEIM</option>
                                    <option value="ANDREWS">ANDREWS</option>
                                    <option value="ANTELOPE">ANTELOPE</option>
                                    <option value="APPLETON">APPLETON</option>
                                    <option value="ASHEVILLE">ASHEVILLE</option>
                                    <option value="ASHLAND">ASHLAND</option>
                                    <option value="ATLANTA EAST">ATLANTA EAST</option>
                                    <option value="ATLANTA NORTH">ATLANTA NORTH</option>
                                    <option value="ATLANTA SOUTH">ATLANTA SOUTH</option>
                                    <option value="ATLANTA WEST">ATLANTA WEST</option>
                                    <option value="AUSTIN">AUSTIN</option>
                                    <option value="AVENEL NEW JERSEY">AVENEL NEW JERSEY</option>
                                    <option value="BAKERSFIELD">BAKERSFIELD</option>
                                    <option value="BALTIMORE">BALTIMORE</option>
                                    <option value="BATON ROUGE">BATON ROUGE</option>
                                    <option value="BILLINGS">BILLINGS</option>
                                    <option value="BIRMINGHAM">BIRMINGHAM</option>
                                    <option value="BOISE">BOISE</option>
                                    <option value="BOSTON-SHIRLEY">BOSTON-SHIRLEY</option>
                                    <option value="BOWLING GREEN">BOWLING GREEN</option>
                                    <option value="BRIDGEPORT">BRIDGEPORT</option>
                                    <option value="BUCKHANNON">BUCKHANNON</option>
                                    <option value="BUFFALO">BUFFALO</option>
                                    <option value="BURLINGTON">BURLINGTON</option>
                                    <option value="CALGARY AB">CALGARY AB</option>
                                    <option value="CANDIA">CANDIA</option>
                                    <option value="CARTERSVILLE">CARTERSVILLE</option>
                                    <option value="CASPER">CASPER</option>
                                    <option value="CENTRAL NEW JERSEY">CENTRAL NEW JERSEY</option>
                                    <option value="CHAMBERSBURG">CHAMBERSBURG</option>
                                    <option value="CHARLESTON">CHARLESTON</option>
                                    <option value="CHARLOTTE">CHARLOTTE</option>
                                    <option value="CHATTANOOGA">CHATTANOOGA</option>
                                    <option value="CHICAGO NORTH">CHICAGO NORTH</option>
                                    <option value="CHICAGO SOUTH">CHICAGO SOUTH</option>
                                    <option value="CHICAGO WEST">CHICAGO WEST</option>
                                    <option value="CHICOUTIMI QC">CHICOUTIMI QC</option>
                                    <option value="CHINA GROVE">CHINA GROVE</option>
                                    <option value="CINCINATTI">CINCINATTI</option>
                                    <option value="CLEARWATER">CLEARWATER</option>
                                    <option value="CLEVELAND">CLEVELAND</option>
                                    <option value="COLORADO SPRINGS">COLORADO SPRINGS</option>
                                    <option value="COLTON">COLTON</option>
                                    <option value="COLUMBIA">COLUMBIA</option>
                                    <option value="COLUMBIA MO">COLUMBIA MO</option>
                                    <option value="COLUMBUS">COLUMBUS</option>
                                    <option value="CONCORD">CONCORD</option>
                                    <option value="CORPUS CHRISTI">CORPUS CHRISTI</option>
                                    <option value="COURTICE ON">COURTICE ON</option>
                                    <option value="CRASHEDTOYS ATLANTA">CRASHEDTOYS ATLANTA</option>
                                    <option value="CRASHEDTOYS EAST BETHEL">CRASHEDTOYS EAST BETHEL</option>
                                    <option value="CRASHEDTOYS MINNEAPOLIS">CRASHEDTOYS MINNEAPOLIS</option>
                                    <option value="CRASHEDTOYS SACRAMENTO">CRASHEDTOYS SACRAMENTO</option>
                                    <option value="CULPEPER">CULPEPER</option>
                                    <option value="DALLAS">DALLAS</option>
                                    <option value="DANVILLE">DANVILLE</option>
                                    <option value="DAVENPORT">DAVENPORT</option>
                                    <option value="DAYTON">DAYTON</option>
                                    <option value="DENVER">DENVER</option>
                                    <option value="DES MOINES">DES MOINES</option>
                                    <option value="DETROIT">DETROIT</option>
                                    <option value="DOTHAN">DOTHAN</option>
                                    <option value="DUNDALK">DUNDALK</option>
                                    <option value="EAST BAY">EAST BAY</option>
                                    <option value="EDMONTON AB">EDMONTON AB</option>
                                    <option value="EL PASO">EL PASO</option>
                                    <option value="ENGLISHTOWN">ENGLISHTOWN</option>
                                    <option value="ERIE">ERIE</option>
                                    <option value="EUGENE">EUGENE</option>
                                    <option value="EXETER">EXETER</option>
                                    <option value="FARGO">FARGO</option>
                                    <option value="FAYETTEVILLE">FAYETTEVILLE</option>
                                    <option value="FLINT">FLINT</option>
                                    <option value="FONTANA">FONTANA</option>
                                    <option value="FORT MYERS">FORT MYERS</option>
                                    <option value="FORT PIERCE">FORT PIERCE</option>
                                    <option value="FORT WAYNE">FORT WAYNE</option>
                                    <option value="FORT WORTH NORTH">FORT WORTH NORTH</option>
                                    <option value="FREDERICKSBURG">FREDERICKSBURG</option>
                                    <option value="FREMONT">FREMONT</option>
                                    <option value="FRESNO">FRESNO</option>
                                    <option value="FT. PIERCE">FT. PIERCE</option>
                                    <option value="FT. WORTH">FT. WORTH</option>
                                    <option value="GLASSBORO EAST">GLASSBORO EAST</option>
                                    <option value="GRAHAM">GRAHAM</option>
                                    <option value="GRAND RAPIDS">GRAND RAPIDS</option>
                                    <option value="GREENSBORO">GREENSBORO</option>
                                    <option value="GREENVILLE">GREENVILLE</option>
                                    <option value="GRENADA">GRENADA</option>
                                    <option value="GULF COAST">GULF COAST</option>
                                    <option value="HALIFAX NS">HALIFAX NS</option>
                                    <option value="HAMILTON ON">HAMILTON ON</option>
                                    <option value="HAMMOND">HAMMOND</option>
                                    <option value="HAMPTON">HAMPTON</option>
                                    <option value="HARRISBURG">HARRISBURG</option>
                                    <option value="HARTFORD">HARTFORD</option>
                                    <option value="HAYWARD">HAYWARD</option>
                                    <option value="HAYWARD-DESERT">HAYWARD-DESERT</option>
                                    <option value="HELENA">HELENA</option>
                                    <option value="HIGH DESERT">HIGH DESERT</option>
                                    <option value="HONOLULU">HONOLULU</option>
                                    <option value="HOUSTON">HOUSTON</option>
                                    <option value="HUNTSVILLE">HUNTSVILLE</option>
                                    <option value="IA-CRASHEDTOYS ELDRIDGE">IA-CRASHEDTOYS ELDRIDGE</option>
                                    <option value="INDIANAPOLIS">INDIANAPOLIS</option>
                                    <option value="IONIA">IONIA</option>
                                    <option value="JACKSON">JACKSON</option>
                                    <option value="JACKSONVILLE">JACKSONVILLE</option>
                                    <option value="KANSAS CITY">KANSAS CITY</option>
                                    <option value="KINCHELOE">KINCHELOE</option>
                                    <option value="KITCHENER ON">KITCHENER ON</option>
                                    <option value="KNOXVILLE">KNOXVILLE</option>
                                    <option value="LAFAYETTE">LAFAYETTE</option>
                                    <option value="LANSING">LANSING</option>
                                    <option value="LAS VEGAS">LAS VEGAS</option>
                                    <option value="LAUREL">LAUREL</option>
                                    <option value="LAVAL QC">LAVAL QC</option>
                                    <option value="LES CEDRES QC">LES CEDRES QC</option>
                                    <option value="LEXINGTON">LEXINGTON</option>
                                    <option value="LINCOLN">LINCOLN</option>
                                    <option value="LITTLE ROCK">LITTLE ROCK</option>
                                    <option value="LITTLETON">LITTLETON</option>
                                    <option value="LONDON ON">LONDON ON</option>
                                    <option value="LONG BEACH">LONG BEACH</option>
                                    <option value="LONG ISLAND">LONG ISLAND</option>
                                    <option value="LONGVIEW">LONGVIEW</option>
                                    <option value="LOS ANGELES">LOS ANGELES</option>
                                    <option value="LOUISVILLE">LOUISVILLE</option>
                                    <option value="LUBBOCK">LUBBOCK</option>
                                    <option value="LUFKIN">LUFKIN</option>
                                    <option value="LUMBERTON">LUMBERTON</option>
                                    <option value="LYMAN">LYMAN</option>
                                    <option value="MACON">MACON</option>
                                    <option value="MADISON">MADISON</option>
                                    <option value="MANCHESTER">MANCHESTER</option>
                                    <option value="MARTINEZ">MARTINEZ</option>
                                    <option value="MASCOUCHE QC">MASCOUCHE QC</option>
                                    <option value="MCALLEN">MCALLEN</option>
                                    <option value="MEBANE">MEBANE</option>
                                    <option value="MEMPHIS">MEMPHIS</option>
                                    <option value="METRO">METRO</option>
                                    <option value="MIAMI">MIAMI</option>
                                    <option value="MILTON ON">MILTON ON</option>
                                    <option value="MILWAUKEE">MILWAUKEE</option>
                                    <option value="MINNEAPOLIS">MINNEAPOLIS</option>
                                    <option value="MISSOULA">MISSOULA</option>
                                    <option value="MOBILE">MOBILE</option>
                                    <option value="MOCKSVILLE">MOCKSVILLE</option>
                                    <option value="MONCTON NB">MONCTON NB</option>
                                    <option value="MONTGOMERY">MONTGOMERY</option>
                                    <option value="MONTREAL QC">MONTREAL QC</option>
                                    <option value="MOOSE JAW SK">MOOSE JAW SK</option>
                                    <option value="NASHVILLE">NASHVILLE</option>
                                    <option value="NEW CASTLE">NEW CASTLE</option>
                                    <option value="NEW ORLEANS">NEW ORLEANS</option>
                                    <option value="NEWBURGH">NEWBURGH</option>
                                    <option value="NORTH BOSTON">NORTH BOSTON</option>
                                    <option value="NORTH CARLESTON">NORTH CARLESTON</option>
                                    <option value="NORTH HOLLYWOOD">NORTH HOLLYWOOD</option>
                                    <option value="NORTH SEATTLE">NORTH SEATTLE</option>
                                    <option value="NORTHERN NEW JERSEY">NORTHERN NEW JERSEY</option>
                                    <option value="NORTHERN VIRGINIA">NORTHERN VIRGINIA</option>
                                    <option value="OKLAHOMA CITY">OKLAHOMA CITY</option>
                                    <option value="OMAHA">OMAHA</option>
                                    <option value="ORLANDO">ORLANDO</option>
                                    <option value="OSHAWA ON">OSHAWA ON</option>
                                    <option value="OTTAWA ON">OTTAWA ON</option>
                                    <option value="PADUCAH">PADUCAH</option>
                                    <option value="PASCO">PASCO</option>
                                    <option value="PENSACOLA">PENSACOLA</option>
                                    <option value="PEORIA">PEORIA</option>
                                    <option value="PERIAN BASIN">PERIAN BASIN</option>
                                    <option value="PHILADELPHIA">PHILADELPHIA</option>
                                    <option value="PHOENIX">PHOENIX</option>
                                    <option value="PITTSBURGH">PITTSBURGH</option>
                                    <option value="PORTAGE">PORTAGE</option>
                                    <option value="PORTLAND">PORTLAND</option>
                                    <option value="PRINCE ALBERT SK">PRINCE ALBERT SK</option>
                                    <option value="PROVIDENCE">PROVIDENCE</option>
                                    <option value="PULASKI">PULASKI</option>
                                    <option value="PUNTA GORDA">PUNTA GORDA</option>
                                    <option value="PUYALLUP">PUYALLUP</option>
                                    <option value="QUEBEC CITY QC">QUEBEC CITY QC</option>
                                    <option value="RALEIGH">RALEIGH</option>
                                    <option value="RANCHO CUCAMONGA">RANCHO CUCAMONGA</option>
                                    <option value="REGINA SK">REGINA SK</option>
                                    <option value="RENO">RENO</option>
                                    <option value="RICHMOND">RICHMOND</option>
                                    <option value="RIVERSIDE">RIVERSIDE</option>
                                    <option value="ROANOKE">ROANOKE</option>
                                    <option value="ROCHESTER">ROCHESTER</option>
                                    <option value="ROSEDALE">ROSEDALE</option>
                                    <option value="SACRAMENTO">SACRAMENTO</option>
                                    <option value="SALT LAKE CITY">SALT LAKE CITY</option>
                                    <option value="SAN ANTONIO">SAN ANTONIO</option>
                                    <option value="SAN BERNARDINO">SAN BERNARDINO</option>
                                    <option value="SAN DIEGO">SAN DIEGO</option>
                                    <option value="SAN JOSE">SAN JOSE</option>
                                    <option value="SASKATOON SK">SASKATOON SK</option>
                                    <option value="SAVANNAH">SAVANNAH</option>
                                    <option value="SCRANTON">SCRANTON</option>
                                    <option value="SEAFORD">SEAFORD</option>
                                    <option value="SEATTLE">SEATTLE</option>
                                    <option value="SHADY SPRING">SHADY SPRING</option>
                                    <option value="SHREVERPORT">SHREVERPORT</option>
                                    <option value="SIKESTON">SIKESTON</option>
                                    <option value="SIOUX FALLS">SIOUX FALLS</option>
                                    <option value="SO SACRAMENTO">SO SACRAMENTO</option>
                                    <option value="SOMERVILLE">SOMERVILLE</option>
                                    <option value="SOUTH BEND">SOUTH BEND</option>
                                    <option value="SOUTH BOSTON">SOUTH BOSTON</option>
                                    <option value="SOUTHERN ILLINOIS">SOUTHERN ILLINOIS</option>
                                    <option value="SOUTHERN NEW JERSEY">SOUTHERN NEW JERSEY</option>
                                    <option value="SPARTANBURG">SPARTANBURG</option>
                                    <option value="SPECIALTY DIVISION">SPECIALTY DIVISION</option>
                                    <option value="SPOKANE">SPOKANE</option>
                                    <option value="SPRINGFIELD">SPRINGFIELD</option>
                                    <option value="ST. PHILIBERT QC">ST. PHILIBERT QC</option>
                                    <option value="ST. CLOUD">ST. CLOUD</option>
                                    <option value="ST. LOUIS">ST. LOUIS</option>
                                    <option value="STOUFFVILLE ON">STOUFFVILLE ON</option>
                                    <option value="SUDBURY ON">SUDBURY ON</option>
                                    <option value="SUFFOLK">SUFFOLK</option>
                                    <option value="SUN VALLEY">SUN VALLEY</option>
                                    <option value="SYRACUSE">SYRACUSE</option>
                                    <option value="TALLAHASSEE">TALLAHASEE</option>
                                    <option value="TAMPA">TAMPA</option>
                                    <option value="TANNER">TANNER</option>
                                    <option value="TAUNTON">TAUNTON</option>
                                    <option value="TEMPLETON">TEMPLETON</option>
                                    <option value="TIDEWATER">TIDEWATER</option>
                                    <option value="TIFTON">TIFTON</option>
                                    <option value="TORONTO ON">TORONTO ON</option>
                                    <option value="TRENTON">TRENTON</option>
                                    <option value="TUCSON">TUCSON</option>
                                    <option value="TULSA">TULSA</option>
                                    <option value="VALLEJO">VALLEJO</option>
                                    <option value="VAN NUYS">VAN NUYS</option>
                                    <option value="VANCOUVER BC">VANCOUVER BC</option>
                                    <option value="VANCOUVER ISLAND BC">VANCOUVER ISLAND BC</option>
                                    <option value="WACO">WACO</option>
                                    <option value="WALTON">WALTON</option>
                                    <option value="WASHINGTON">WASHINGTON</option>
                                    <option value="WEST PALM BEACH">WEST PALM BEACH</option>
                                    <option value="WEST WARREN">WEST WARREN</option>
                                    <option value="WESTERN COLORADO">WESTERN COLORADO</option>
                                    <option value="WHEELING">WHEELING</option>
                                    <option value="WICHITA">WICHITA</option>
                                    <option value="WILMINGTON">WILMINGTON</option>
                                    <option value="WINCHESTER ON">WINCHESTER ON</option>
                                    <option value="WINNIPEG MB">WINNIPEG MB</option>
                                    <option value="YORK HAVEN">YORK HAVEN</option>
                                    <option value="YORK SPRINGS">YORK SPRINGS</option>

                                </select>
                            </label>
                            <div className="betterButton-container">
                                <BetterButton onClick={handleCalculate} label={t("CalculatorButtonText")} />
                            </div>
                            <p>{result}</p>
                        </div>


                    </div>
                    <div className="procedureContainer-content__lines__bottom">
                        <div className="procedureContainer-content__lines__blue"></div>
                        <div className="procedureContainer-content__lines__red"></div>
                        <div className="procedureContainer-content__lines__blue"></div>
                    </div>
                </div>
            </div>
            <section className="calculatorDescriptionContainer">
                <h3>{t("CalculatorH3Second")}</h3>
                <li>{t("CalculatorLi1")}</li>
                <li>{t("CalculatorLi2")}</li>
                <li>{t("CalculatorLi3")}</li>
                <li>{t("CalculatorLi4")}</li>
                <li>{t("CalculatorLi5")}</li>
                <li>{t("CalculatorLi6")}</li>
                <h3>{t("CalculatorH3Third")}</h3>
                <li>{t("CalculatorLi7")}</li>
                <li>{t("CalculatorLi8")}</li>
                <li>{t("CalculatorLi9")}</li>
                <div className="procedureContainer-content__lines__bottom">
                    <div className="procedureContainer-content__lines__blue"></div>
                    <div className="procedureContainer-content__lines__red"></div>
                    <div className="procedureContainer-content__lines__blue"></div>
                </div>
            </section>

        </div>
    )

}

export default Calculator;