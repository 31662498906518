import React from "react";
import {useNavigate} from "react-router-dom";
import "../../sass/BetterButton.css";


function BetterButton({link, label, onClick}) {
    const navigate = useNavigate();

    const handleClick = () => {
        if(onClick) {
            onClick();
        }
        navigate(link);
    };

    return(
        <div className="betterButton-container">
            <div className="betterButton-container__blue"></div>
            <div className="betterButton-container__red"></div>
            <button className="betterButton-container-button" onClick={handleClick}>
                <div className="betterButton-text">
                    {label}
                </div>
            </button>
        </div>
    );
}


export default BetterButton;