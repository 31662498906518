import React from "react";
import "../../sass/Footer.css";
import logo from '../../img/uscarsLogo.svg';
import youtube from '../../img/socialmedia/youtube.svg';
import instagram from '../../img/socialmedia/instagram.svg';
import facebook from '../../img/socialmedia/facebook.svg';
import otomoto from '../../img/socialmedia/otomoto.svg';
import BetterButton from "../smallComponents/BetterButton";
import {useTranslation} from "react-i18next";

const Footer = () => {

    const {t} = useTranslation();
    const scrollToTopFooter = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <div>
            <footer>
                <img src={logo} alt="Logo uscars" className="logo__mobile"/>
                <div className="footerContainer">
                    <div className="footerContainer-logoRow">
                        <img src={logo} alt="Logo uscars" className="logo"/>

                        <div className="betterButton-container">
                            <BetterButton onClick={scrollToTopFooter} label={t("FooterButton")} className="betterButton-display"/>
                        </div>
                        <p>© 2023 USCARS. All rights reserved</p>
                    </div>


                    <div className="footerContainer-socials">
                        <img src={youtube} alt="youtube logo - link to uscars youtube account" onClick={() => {window.open('https://www.youtube.com/@USCARSimportzUSA')}}/>
                        <img src={instagram} alt="instagram logo - link to uscars instagram account" onClick={() => {window.open('https://www.instagram.com/uscarsusa/')}}/>
                        <img src={facebook} alt="facebook logo - link to uscars facebook account" onClick={() => {window.open('https://www.facebook.com/usauscars')}}/>
                        <img src={otomoto} alt="otomoto logo - link to uscars otomoto account" onClick={() => {window.open('https://us-cars.otomoto.pl/inventory')}}/>
                    </div>
                </div>
                <div className="footerMobileContainer">
                    <div className="footer-line"></div>
                    <div className="footerMobileContainer-socials">
                        <img src={youtube} className="yt" alt="youtube logo - link to uscars youtube account" onClick={() => {window.open('https://www.youtube.com/@USCARSimportzUSA')}}/>
                        <img src={instagram} alt="instagram logo - link to uscars instagram account" onClick={() => {window.open('https://www.instagram.com/uscarsusa/')}}/>
                        <img src={facebook} alt="facebook logo - link to uscars facebook account" onClick={() => {window.open('https://www.facebook.com/usauscars')}}/>
                        <img src={otomoto} alt="otomoto logo - link to uscars otomoto account" onClick={() => {window.open('https://us-cars.otomoto.pl/inventory')}}/>
                    </div>
                    <div className="betterButton-containerFooter">

                        <BetterButton label={t("FooterButton")} onClick={scrollToTopFooter}/>
                    </div>
                    <p>© 2023 USCARS. All rights reserved</p>

                </div>

            </footer>
        </div>
    )
}

export default Footer;
