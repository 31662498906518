import i18n from "i18next";
import {initReactI18next} from "react-i18next";


const resources = {
    en: {
        translation: {
            "NavbarProcedure": "Procedure",
            "NavbarCalculator": "Calculator",
            "NavbarRealizations": "Realizations",
            "NavbarAboutUs": "About Us",
            "NavbarContact": "Contact",

            "HomeMainText": "American vehicle import",

            "ProceduraHomeH2": "Procedure",
            "ProcedureHomeTitle1": "Vehicle Choice",
            "ProcedureHomeTitle2": "Agreement and Deposit",
            "ProcedureHomeTitle3": "Payment for the Car",
            "ProcedureHomeTitle4": "Transport",
            "ProcedureHomeTitle5": "Customs Clearance",
            "ProcedureHomeTitle6": "Vehicle Pickup",
            "ProcedureHomeDesc1": "We start the collaboration by searching for the right car. Then, the car is verified by us in terms of history and origin.",
            "ProcedureHomeDesc2": "After choosing the vehicle, we sign an agreement which is a form of security for both parties. The client is also obliged to pay a deposit. If the auction ends unsuccessfully, the deposit is returned or remains for subsequent auctions.",
            "ProcedureHomeDesc3": "After receiving information about the won auction, the Client is obliged to make a full payment for the costs associated with the purchase and transport of the car to Europe directly to the company account in the United States within 2 working days. This is a necessary condition to avoid additional fees.",
            "ProcedureHomeDesc4": "After paying for the car, we can start the process of transporting the car to Europe. Upon arrival at the port, the car is loaded into a container and sets off for Europe.",
            "ProcedureHomeDesc5": "After the vehicle arrives at the port in Rotterdam, we organize customs and tax payments. The entire customs clearance procedure lasts several days, and you only have to sign the appropriate power of attorney and pay the fees directly to the agency's account after it is completed.",
            "ProcedureHomeDesc6": "After payment, the car is released. Then we organize the transport of the car to the selected address. At the client's request, we can take care of repairing the car and all formalities related to preparing for registration. The only thing left for the client is registration and picking up their new car.",

            "AboutUsHomeH2": "About us",
            "AboutUsHomeTitle": "We have been in the industry for 6 years",
            "AboutUsHomeDescription": "USCARS is a leading company specializing in the import of cars, motorcycles, ATVs, and boats from the USA and Canada. We approach each client individually. Our work is characterized by precision, experience, and honesty. Each car is properly verified by us before purchase.",
            "AboutUsHomeMore": "Learn more",
            "AboutUsHomeSquare1Main": "1556",
            "AboutUsHomeSquare1Text": "Imported cars",
            "AboutUsHomeSquare2Main": "62",
            "AboutUsHomeSquare2Text": "In the last month",
            "AboutUsHomeSquare3Main": "100%",
            "AboutUsHomeSquare3Text": "Satisfied customers",
            "AboutUsHomeSquare4Main": "6",
            "AboutUsHomeSquare4Text": "Years in the industry",

            "CarouselReview1": "I recommend it to everyone. I was able to find and bid on a car efficiently. The communication with the guys is excellent, they always provide advice and guidance in choosing a car. When I buy another car, I will definitely use their services again.",
            "CarouselReview2": "Even though I didn't proceed with the purchase and bidding because I backed out, there were no issues from USCars regarding the cancellation. The deposit money is returned to the account. I recommend them for their honesty and quick email responses as well as over the phone. Regards.",
            "CarouselReview3": "I recommend them. Sometimes it's hard to get through on the phone, but the guys are honest and reliable from start to finish. They handle our order step by step as it should be. You can trust these people without any concerns! Wojciech Wozniak.",
            "CarouselReview4": "If you know exactly what you want and are willing to wait a bit for your dream car from the USA, then this company is for you! They will deliver your car to your doorstep in the same condition as you bought it on another continent :) Nothing more than fulfilling your desires. I recommend.",
            "CarouselReview5": "I am very pleased with the cooperation with US cars and can confidently recommend them to anyone looking for a car from overseas. Great communication and comprehensive service. I can wholeheartedly recommend this company. I wish for more such transactions.",
            "CarouselReview6": "I recommend this company because it started with great service and assistance in everything, and ended with a cool ride delivered to my home without any issues. So, if anyone is planning to import a car, they can confidently contact this company.",
            "CarouselReview7": "Very reliable company. Hassle-free communication, no hidden costs, they found and imported the car faster than expected. I recommend!",
            "CarouselReview8": "You can tell the guys are passionate about what they do. Constant communication with the customer and keeping them updated on what's happening with the car!",
            "CarouselReview9": "Very professional service, the gentlemen answer all questions thoroughly. The process of importing a car from A to Z is conducted professionally. I recommend it to anyone who is still considering.",
            "CarouselReview10": "I can wholeheartedly recommend this company. Excellent assistance in choosing a car and estimating repair costs. Efficient transport. We are constantly informed about our vehicle. Friendly service. If I'm going to import another car to the country, it will only be with USCARS!",


            "CalculatorH2": "Calculator",
            "CalculatorText1": "Importing a vehicle from America to Poland entails certain costs, but despite these charges, bringing in a car remains a more favorable option compared to buying a new one locally.",
            "CalculatorText2": "Thanks to our calculator, you can accurately estimate the total cost of a particular vehicle.",
            "CalculatorH3First": "Currency Exchange Rates",
            "CalculatorCalc1": "Vehicle Price (USD)",
            "CalculatorCalc2": "Location",
            "CalculatorCalc3": "Select Location",
            "CalculatorButtonText": "Check Cost",
            "CalculatorTable1": "Currency",
            "CalculatorTable2": "Exchange Rate",
            "CalculatorH3Second": "Costs of importing a vehicle from the USA and Canada:",
            "CalculatorLi1": "Handling formalities (commission)",
            "CalculatorLi2": "Vehicle cost",
            "CalculatorLi3": "Auction fee",
            "CalculatorLi4": "Transport to Europe",
            "CalculatorLi5": "Customs clearance",
            "CalculatorLi6": "Transport within Europe",
            "CalculatorH3Third": "Additional costs",
            "CalculatorLi7": "Preparation of registration documents",
            "CalculatorLi8": "Excise duty",
            "CalculatorLi9": "Repair",

            "RealizationsH2": "Realizations",


            "AboutUsH2": "About Us",
            "AboutUsText1": "USCARS is a leading company specializing in the import of cars, motorcycles, ATVs, and boats from the USA and Canada.",
            "AboutUsText2": "Our work is characterized by diligence, experience, and honesty. Every vehicle is thoroughly verified by us before purchase. We meet even the highest expectations with due diligence. We approach every task with 100% commitment, and there's nothing impossible for us, as evidenced by the recommendations we receive.",
            "AboutUsText3": "We collaborate with all auction houses in the USA and Canada.",

            "ContactH2": "Contact",
            "ContactH3": "Form",
            "ContactName": "First Name",
            "ContactSurname": "Last Name",
            "ContactPhone": "Phone",
            "ContactMess": "Message",
            "ContactButton": "Send Message",

            "Accordion1Title": "How long does it take to import a vehicle to Poland?",
            "Accordion1Text": "The time to import a vehicle depends on many elements, including location. On average, the waiting time for vehicle delivery is about 6-8 weeks.",
            "Accordion2Title": "How to estimate the final car price at auction?",
            "Accordion2Text": "Determining the exact final price of a vehicle at auction is difficult, but our experts can help set an attractive starting price, allowing you to save between 30% to 50% of the vehicle's market value. They analyze repair costs, transport, taxes, and market trends for similar vehicles. You can also use the calculator on our website to estimate the vehicle's value before bidding.",
            "Accordion3Title": "Where do the damaged cars come from?",
            "Accordion3Text": "The majority of damaged cars come from insurance companies and auctions held throughout the USA. In addition, vehicles are imported from car dealers, leasing companies, and directly from private owners.",
            "Accordion4Title": "How to deposit a security deposit?",
            "Accordion4Text": "You can make a deposit payment by transferring to our bank account or by cash payment at our headquarters. We accept various currencies, including PLN, USD, and EUR.",
            "Accordion5Title": "Do you deal with repair and adaptation of the car to Polish regulations?",
            "Accordion5Text": "Our company specializes in comprehensive car repair. We work with professional car workshops that offer vehicle repair services from scratch. Additionally, we adjust the cars to the requirements valid in Poland.",

            "FaqH3": "Didn't find an answer?",
            "FaqText": "Call, write, or visit us. We will be happy to clarify any doubts.",
            "FaqButton": "Contact",

            "FooterButton": "Back to top",

            "emptyAmount": "Please enter a vehicle amount.",
            "amountBelowZero": "The vehicle price must be greater than 0.",
            "emptyLocation": "Please select a location.",

            "success": "Estimated amount is displayed below.",

            "emptyName": "Please enter a name.",
            "emptySurname": "Please enter a surname.",
            "emptyEmail": "Please enter an email.",
            "emptyPhone": "Please provide a phone number.",
            "emptyMessage": "Please provide an email content.",
            "sendingContact": "Sending email...",
            "successContact": "Email sent successfully!",
            "errorContact": "There was an error sending the email."
        }
    },
    pl: {
        translation: {
            "NavbarProcedure": "Procedura",
            "NavbarCalculator": "Kalkulator",
            "NavbarRealizations": "Realizacje",
            "NavbarAboutUs": "O Nas",
            "NavbarContact": "Kontakt",

            "HomeMainText": "Import pojazdów z USA",

            "ProceduraHomeH2": "Procedura",
            "ProcedureHomeTitle1": "Wybór samochodu",
            "ProcedureHomeTitle2": "Umowa oraz kaucja",
            "ProcedureHomeTitle3": "Płatność za samochód",
            "ProcedureHomeTitle4": "Transport",
            "ProcedureHomeTitle5": "Odprawa celna",
            "ProcedureHomeTitle6": "Odbiór samochodu",
            "ProcedureHomeDesc1": "Współpracę rozpoczynamy od wyszukania odpowiedniego samochodu. Następnie auto jest przez nas weryfikowane pod kątem historii oraz pochodzenia.",
            "ProcedureHomeDesc2": "Po dokonaniu wyboru pojazdu, podpisujemy umowę która jest formą zabezpieczenia dla obu stron. Klient jest także zobligowany do wpłaty kaucji. W przypadku niepomyślnego zakończenia licytacji kaucja jest zwracana, bądź pozostaje na poczet kolejnych aukcji.",
            "ProcedureHomeDesc3": "Po otrzymaniu informacji o wygranej aukcji, Klient jest zobowiązany dokonać pełnej opłaty za koszty związane z zakupem oraz transportem samochodu do Europy bezpośrednio na konto firmowe w Stanach Zjednoczonych w ciągu 2 dni roboczych. Jest to warunek konieczny, aby uniknąć dodatkowo naliczonych opłat.",
            "ProcedureHomeDesc4": "Po dokonaniu płatności za samochód, możemy rozpocząć procedurę transportu auta do Europy. Po dotarciu do portu auto zostaje załadowane do kontenera i wyrusza w podróż do Europy.",
            "ProcedureHomeDesc5": "Po dotarciu pojazdu do portu w Rotterdam organizujemy opłaty celno-skarbowe. Cała procedura oclenia trwa kilka dni, a Ty musisz tylko podpisać stosowne pełnomocnictwo i po jej zakończeniu zapłacić należności bezpośrednio na konto agencji.",
            "ProcedureHomeDesc6": "Po zapłacie należności, auto zostaje zwolnione. Wtedy organizujemy transport pojazdu pod wybrany adres. Na życzenie klienta możemy zająć się naprawą samochodu oraz wszelkimi formalnościami związanymi z przygotowaniem do rejestracji. Klientowi zostaje jedynie rejestracja i odbiór swojego nowego samochodu.",

            "AboutUsHomeH2": "O nas",
            "AboutUsHomeTitle": "Działamy w branży 6 lat",
            "AboutUsHomeDescription": "USCARS to czołowa firma zajmująca się importem samochodów, motocykli, quadów i łodzi z USA oraz Kanady. Podchodzimy do każdego klienta indywidualnie. Naszą pracę cechuje staranność, doświadczenie oraz uczciwość. Każde auto jest przez nas należycie zweryfikowane przed zakupem.",
            "AboutUsHomeMore": "Dowiedz się więcej",
            "AboutUsHomeSquare1Main": "1556",
            "AboutUsHomeSquare1Text": "Sprowadzonych samochodów",
            "AboutUsHomeSquare2Main": "62",
            "AboutUsHomeSquare2Text": "W ostatnim miesiącu",
            "AboutUsHomeSquare3Main": "100%",
            "AboutUsHomeSquare3Text": "Zadowolonych klientów",
            "AboutUsHomeSquare4Main": "6",
            "AboutUsHomeSquare4Text": "Lat w branży",

            "CarouselReview1": "Polecam wszystkim. Sprawnie udało się znaleźć i wylicytować samochód. Z chłopakami jest bardzo dobry kontakt, zawsze podpowiedzą i doradzą w wyborze samochodu, gdy będę kupował kolejny samochód na pewno skorzystam ponownie.",
            "CarouselReview2": "Choć do zakupu i licytacji nie doszło, ponieważ zrezygnowałem nie było żadnego problemu ze strony USCars na temat rezygnacji. Kasa za kaucje wraca na konto. Polecam za uczciwość i szybkie odpowiedzi email a także telefonicznie. Pozdrawiam.",
            "CarouselReview3": "Polecam, czasami ciężko się dodzwonić ale chlopaki są uczciwi i rzetelni od początku do końca krok po kroku realizują nasze zamówienie tak jak należy. Bez obaw można zaufać tym ludziom! Wojciech Wozniak",
            "CarouselReview4": "Jeśli wiesz czego konkretnie chcesz i jesteś gotówy trochę poczekać na marzenie z USA, to ta firma jest dla Ciebie! Dostarczy Twoje auto pod dom w takim stanie w jakim kupiłeś je na innym kontynencie :) Nic więcej tylko spełnienie Twoich pragnień. Polecam.",
            "CarouselReview5": "Jestem bardzo zadowolony ze współpracy z US cars i spokojnie mogę polecić wszystkim szukającym auta z za oceanu. Super kontakt i kompleksowa obsługa. Z czystym sumieniem mogę polecić ta firmę. Oby więcej takich realizacji.",
            "CarouselReview6": "Polecam firmę z tego powodu ze zaczęło się od super obsługi i pomocy we wszystkim a skoczyło na fajnej furce pod domem bez żadnych problemów. Więc jeżeli ktoś planuje sobie sprowadzić jakiś samochód to śmiało może kontaktować się z firmą.",
            "CarouselReview7": "Bardzo rzetelna firma. Bezproblemowy kontakt, brak ukrytych kosztów, wyszukali i sprowadzili samochód w szybszym terminie niż przewidywano. Polecam!",
            "CarouselReview8": "Widać że chłopaki robią to z pasją. Stały kontakt z klientem i informowanie na bieżąco co się dzieje z samochodem!",
            "CarouselReview9": "Bardzo profesjonalna obsługa, panowie odpowiadają wyczerpująco na wszystkie pytania, usługa sprowadzenia samochodu od A do Z przeprowadzona profesjonalnie, polecam każdemu kto się jeszcze zastanawia.",
            "CarouselReview10": "Z czystym sumieniem mogę polecić tą firmę. Znakomita pomoc przy wybraniu samochodu oraz wycenie naprawy. Sprawny transport. Na bieżąco jesteśmy informowani o naszym pojeździe. Miła obsługa. Jeżeli będę sprowadzać do kraju następny samochód to tylko z USCARS!",

            "CalculatorH2": "Kalkulator",
            "CalculatorText1": "Import pojazdu z Ameryki do Polski niesie za sobą pewne koszty, jednak mimo tych opłat, sprowadzenie samochodu pozostaje bardziej korzystną opcją w porównaniu z zakupem nowego lokalnie.",
            "CalculatorText2": "Dzięki naszemu kalkulatorowi możesz dokładnie oszacować całkowity koszt danego pojazdu.",
            "CalculatorH3First": "Kursy Walut",
            "CalculatorCalc1": "Cena pojazdu (USD)",
            "CalculatorCalc2": "Lokalizacja",
            "CalculatorCalc3": "Wybierz Lokalizacje",
            "CalculatorButtonText": "Sprawdź Koszt",
            "CalculatorTable1": "Waluta",
            "CalculatorTable2": "Kurs",
            "CalculatorH3Second": "Koszty importu pojazdu z USA i Kanady:",
            "CalculatorLi1": "Załatwienie formalności (prowizja)",
            "CalculatorLi2": "Koszt pojazdu",
            "CalculatorLi3": "Prowizja aukcyjna",
            "CalculatorLi4": "Transport do Europy",
            "CalculatorLi5": "Odprawa celna",
            "CalculatorLi6": "Transport po Europie",
            "CalculatorH3Third": "Dodatkowe koszty",
            "CalculatorLi7": "Przygotowanie dokumentów do rejestracji",
            "CalculatorLi8": "Akcyza",
            "CalculatorLi9": "Naprawa",

            "RealizationsH2": "Realizacje",

            "AboutUsH2": "O Nas",
            "AboutUsText1": "USCARS to czołowa firma zajmująca się importem samochodów, motocykli, quadów i łodzi z USA oraz Kanady.",
            "AboutUsText2": "Naszą pracę cechuje staranność, doświadczenie oraz uczciwość. Każde auto jest przez nas należycie zweryfikowane przed zakupem. Spełniamy nawet najwyższe oczekiwania z zachowaniem należytej staranności. Do każdego zadania podchodzimy na 100% i nie ma dla nas rzeczy niemożliwych, o czym świadczą udzielane nam rekomendacje.",
            "AboutUsText3": "Współpracujemy z wszystkimi domami aukcyjnymi w USA oraz Kanadzie.",

            "ContactH2": "Kontakt",
            "ContactH3": "Formularz",
            "ContactName": "Imię",
            "ContactSurname": "Nazwisko",
            "ContactPhone": "Telefon",
            'ContactMess': "Wiadomość",
            "ContactButton": "Wyślij wiadomość",

            "Accordion1Title": "Ile trwa import pojazdu do Polski?",
            "Accordion1Text": "Czas importu pojazdu zależy od wielu elementów, między innymi od lokalizacji. W przybliżeniu, średni czas oczekiwania na dostarczenie pojazdu wynosi około 6-8 tygodni.",
            "Accordion2Title": "Jak oszacować ostateczną cenę auta na aukcji?",
            "Accordion2Text": "Określenie dokładnej końcowej ceny pojazdu na aukcji jest trudne, ale nasi eksperci mogą pomóc ustalić atrakcyjną cenę wyjściową, pozwalającą zaoszczędzić od 30% do 50% wartości rynkowej pojazdu. Analizują koszty napraw, transportu, podatki oraz rynkowe trendy dla podobnych pojazdów. Możesz też skorzystać z kalkulatora na naszej stronie internetowej, aby samemu oszacować wartość auta przed licytacją.",
            "Accordion3Title": "Skąd pochodzą uszkodzone auta?",
            "Accordion3Text": "Przeważająca część aut uszkodzonych pochodzi z firm ubezpieczeniowych oraz z aukcji prowadzonych na terenie całego USA. Dodatkowo, pojazdy sprowadzane są od dealerów samochodowych, firm leasingowych oraz bezpośrednio od prywatnych właścicieli.",
            "Accordion4Title": "Jak wpłacić kaucję?",
            "Accordion4Text": "Możesz dokonać płatności kaucji poprzez przelew na nasze konto bankowe lub wpłatę gotówkową w naszej siedzibie. Akceptujemy różne waluty, w tym PLN, USD i EUR.",
            "Accordion5Title": "Czy zajmujecie się naprawą oraz przystosowaniem samochodu do polskich przepisów?",
            "Accordion5Text": "Nasza firma  specjalizuje się w kompleksowej naprawie aut. Współpracujemy z profesjonalnymi warsztatami samochodowymi, które świadczą usługi naprawy pojazdów od podstaw. Dodatkowo, zajmujemy się dostosowaniem samochodów do wymogów obowiązujących w Polsce.",

            "FaqH3": "Nie znalazłeś odpowiedzi?",
            "FaqText": "Zadzwoń, napisz, albo nas odwiedź. Z wielką chęcią rozwiejemy wszelkie wątpliwości.",
            "FaqButton": "Kontakt",

            "FooterButton": "Wróć na górę",

            "emptyAmount": "Proszę podać kwotę pojazdu.",
            "amountBelowZero": "Cena pojazdu musi być większa niż 0.",
            "emptyLocation": "Proszę wybrać lokalizacje.",

            "success": "Szacowana kwota wyświetla się poniżej.",

            "emptyName": "Proszę podać imię.",
            "emptySurname": "Proszę podać nazwisko.",
            "emptyEmail": "Proszę podać email.",
            "emptyPhone": "Proszę podać numer telefonu.",
            "emptyMessage": "Proszę podać treść maila.",
            "sendingContact": "Wysyłanie emaila...",
            "successContact": "Email został wysłany pomyślnie!",
            "errorContact": "Wystąpił błąd podczas wysyłania emaila."
        }
    }
};


i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "pl",
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;