import React, {useState} from "react";
import '../../sass/Accordion.css';
import arrow from '../../img/arrowIcon.svg';


const Accordion = ({ title, text}) => {
    const [active, setActive] = useState(false);

    const handleClick = () => {
        setActive((prevActive) => !prevActive);
    };

    return (
        <div className="accordion">
            <div className="accordionHead">
                <div className="container" onClick={handleClick}>
                    <p>{title}</p>
                    <span className={`icon ${active ? 'rotate' : 'rotateBackwards'}`}>
            <img
                src={arrow}
                alt="Custom arrow icon"
                className="customIcon"
            />
          </span>
               </div>
            </div>

            <div className={`accordionContent ${active ? 'show' : ''}`}>
                <div className="container">
                    <p>{text}</p>
                </div>
            </div>
        </div>
    );
}

export default Accordion;