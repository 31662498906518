import React, {useState} from "react";
import "../../sass/Navbar.css";
import logo from '../../img/uscarsLogo.svg';
import langIcon from '../../img/langIcon.svg';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Navbar = () => {
    const {t, i18n} = useTranslation();

    const toggleLanguage = () => {
        const newLang = i18n.language === 'pl' ? 'en' : 'pl';
        i18n.changeLanguage(newLang);
    };

    const [isHamburgerOpen, setHamburgerOpen] = useState(false);
    const [active, setActive] = useState("nav-linkContainer");
    const navHamburger = () => {
        setHamburgerOpen(prevState => !prevState);
        active === 'nav-linkContainer' ? setActive('nav-linkContainer nav-mobileActive') : setActive('nav-linkContainer');
    }

    const removeActive = () => {
        active === 'nav-linkContainer nav-mobileActive' ? setActive('nav-linkContainer') : setActive('nav-linkContainer nav-mobileActive')
    }

    const removeActiveLogo = () => {

        setActive('nav-linkContainer');
    }
    return (
        <nav>
            <div className="nav-Container">

                <ul className="nav-menu">

                    <Link to="/"><img className="nav-logo" src={logo} alt="Logo uscars"
                                      onClick={removeActiveLogo}/></Link>

                    <div className="nav-background__mobile"></div>


                    <div className="nav-divider"></div>

                    <div className={active}>
                        <li onClick={removeActive}>
                            <Link to="/procedura" className="nav-link">{t("NavbarProcedure")}</Link>
                        </li>
                        <li onClick={removeActive}>
                            <Link to="/kalkulator" className="nav-link">{t("NavbarCalculator")}</Link>
                        </li>
                        <li onClick={removeActive}>
                            <Link to="/realizacje" className="nav-link">{t("NavbarRealizations")}</Link>
                        </li>
                        <li onClick={removeActive}>
                            <Link to="/o-nas" className="nav-link">{t("NavbarAboutUs")}</Link>
                        </li>
                        <li onClick={removeActive}>
                            <Link to="/kontakt" className="nav-link">{t("NavbarContact")}</Link>
                        </li>
                        <li onClick={removeActive}>
                            <Link to="/faq" className="nav-link">FAQ</Link>
                        </li>
                        <div className="mobileLang" onClick={toggleLanguage}>
                            <img src={langIcon} alt="Language settings"/>
                            <button>{i18n.language === 'pl' ? 'English' : 'Polski'}</button>
                        </div>

                    </div>
                </ul>
                <div className="nav-langSetting" onClick={toggleLanguage}>
                    <img src={langIcon} alt="Language settings"/>
                    <button>{i18n.language === 'pl' ? 'English' : 'Polski'}</button>
                </div>

                    <div onClick={navHamburger} className="nav-hamburger">
                        <div className={`line line1 ${isHamburgerOpen ? 'expandAnimation' : ''}`}></div>
                        <div className={`line line2 ${isHamburgerOpen ? 'expandAnimation' : ''}`}></div>
                        <div className={`line line3 ${isHamburgerOpen ? 'expandAnimation' : ''}`}></div>
                    </div>


            </div>
        </nav>
    )
};

export default Navbar;
