import React, { useState } from "react";
import "../../sass/Procedure.css";
import BetterButton from "../smallComponents/BetterButton";
import redCar from "../../img/redCar.png";
import { useTranslation } from "react-i18next";

const ProcedureHome = () => {
    const { t } = useTranslation();

    const [activeButton, setActiveButton] = useState(0);
    const [fadeClass, setFadeClass] = useState("");

    const handleClickTitle = (index) => {
        setActiveButton(index);
        setFadeClass("fade-in-out");
        setTimeout(() => {
            setFadeClass("");
        }, 700);
    };

    const displayTitle = t(`ProcedureHomeTitle${activeButton + 1}`);
    const displayText = t(`ProcedureHomeDesc${activeButton + 1}`);
    const displayNumber = ["01", "02", "03", "04", "05", "06"][activeButton];

    return (
        <div className="procedureContainer">
            <h2 className="h2Text">{t("ProceduraHomeH2")}</h2>
            <div className="procedureContainer-content__lines">
                <div className="procedureContainer-content__lines__blue"></div>
                <div className="procedureContainer-content__lines__red"></div>
                <div className="procedureContainer-content__lines__blue"></div>
            </div>
            <div className="procedureContainer-content__elements">
                <div className={`procedureContainer-content__text ${fadeClass}`}>
                    <p className="title">{displayTitle}</p>
                    <p className="text">{displayText}</p>
                    <BetterButton link="/procedura" label={t("AboutUsHomeMore")} />
                </div>

                <div className={`procedureContainer-content__number ${fadeClass}`}>{displayNumber}</div>

                <div className="procedureContainer-content-buttonContainer" style={{ zIndex: '5' }}>
                    {["ProcedureHomeTitle1", "ProcedureHomeTitle2", "ProcedureHomeTitle3", "ProcedureHomeTitle4", "ProcedureHomeTitle5", "ProcedureHomeTitle6"].map((key, index) => (
                        <button className="procedureContainer-content-buttonContainer__dottedButton"
                                onClick={() => handleClickTitle(index)} key={index}>{t(key)}
                            <div className={`procedureContainer-content-buttonContainer__dottedButton__dot ${activeButton === index ? "activeDot" : ""}`}></div>
                        </button>
                    ))}
                </div>

                <img className="procedureContainer-content-redCar" src={redCar} alt="red car" />

                <div className="procedureContainer-content__lines__bottom">
                    <div className="procedureContainer-content__lines__blue"></div>
                    <div className="procedureContainer-content__lines__red"></div>
                    <div className="procedureContainer-content__lines__blue"></div>
                </div>
            </div>
        </div>
    );
}

export default ProcedureHome;
