import React from "react";
import '../../sass/Faq.css';
import faqImg from '../../img/subpagesHero/faq_01.png';
import HeadImg from "../smallComponents/HeadImg";
import Accordion from "../smallComponents/Accordion";
import BetterButton from "../smallComponents/BetterButton";
import {useTranslation} from "react-i18next";

const Faq = () => {

    const {t} = useTranslation();

    return(
     <div>
         <HeadImg src={faqImg}/>
         <div className="faqContainerBg">
             <div className="faqContainer">
                 <h2 className="faqH2">Faq</h2>
                 <div className="faqContainer-elements">
                     <Accordion title={t("Accordion1Title")} text={t("Accordion1Text")} />
                     <Accordion title={t("Accordion2Title")} text={t("Accordion2Text")} />
                     <Accordion title={t("Accordion3Title")} text={t("Accordion3Text")} />
                     <Accordion title={t("Accordion4Title")} text={t("Accordion4Text")} />
                     <Accordion title={t("Accordion5Title")} text={t("Accordion5Text")} />
                 </div>
                 <div className="procedureContainer-content__lines__bottom">
                     <div className="procedureContainer-content__lines__blue"></div>
                     <div className="procedureContainer-content__lines__red"></div>
                     <div className="procedureContainer-content__lines__blue"></div>
                 </div>
             </div>
         </div>
         <section className="faqDescriptionContainer">
             <h3>{t("FaqH3")}</h3>
             <p>{t("FaqText")}</p>

             <div className="betterButton-container">
                 <BetterButton label={t("FaqButton")} link="/kontakt"/>
             </div>
             <div className="procedureContainer-content__lines__bottom">
                 <div className="procedureContainer-content__lines__blue"></div>
                 <div className="procedureContainer-content__lines__red"></div>
                 <div className="procedureContainer-content__lines__blue"></div>
             </div>
         </section>
     </div>
    );
}


export default Faq;