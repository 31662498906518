import React from "react";
import "../../sass/HeroView.css";
import bgVideo from '../../img/Main_Loop.mp4';
import bgVideoMobile from '../../img/Main_Loop_Mobile_01.mp4';
import lineMobile from '../../img/lines/line_mobile.svg';
import lineFullHD from "../../img/lines/line_1920.svg";
import lineQuadHD from "../../img/lines/line_2560.svg";
import lineQuadFull from "../../img/lines/line_2100v2.svg";
import lineTablet from "../../img/lines/line_tablet.svg";
import {useTranslation} from "react-i18next";


const HeroHome = () => {
    const {t} = useTranslation();

    return (
        <div className="heroViewContainer">
            <video className="videoLoop" src={bgVideo} playsInline autoPlay loop muted></video>
            <video className="videoLoopMobile" src={bgVideoMobile} playsInline autoPlay loop muted></video>
            <img className="heroViewContainer-content__fullHD-mainLine" src={lineFullHD} alt="line - visual addon" />
            <img className="heroViewContainer-content__quadFull-mainLine" src={lineQuadFull} alt="line - visual addon" />
            <img className="heroViewContainer-content__quadHD-mainLine" src={lineQuadHD} alt="line - visual addon" />
            <img className="heroViewContainer-content__tablet-mainLine" src={lineTablet} alt="line - visual addon" />
            <img className="heroViewContainer-content__mobile-mainLine" src={lineMobile} alt="line - visual addon" />
            <div className="heroViewContainer-content">
                <h1>{t("HomeMainText")}</h1>
                <div className="heroViewContainer-content__lines">
                    <div className="heroViewContainer-content__lines__blue"></div>
                    <div className="heroViewContainer-content__lines__red"></div>
                    <div className="heroViewContainer-content__lines__blue"></div>
                </div>
            </div>
        </div>
    )
}


export default HeroHome;

