import React from "react";
import "../../sass/AboutUs.css";
import BetterButton from "../smallComponents/BetterButton";
import Carousel from "../smallComponents/carousel";
import blueCar from "../../img/blueCar.png";
import {useTranslation} from "react-i18next";

const AboutUsHome = () => {

    const { t } = useTranslation();
    return (
        <div className="aboutUsContainer">
            <h2 className="h2Text">{t("AboutUsHomeH2")}</h2>
            <div className="aboutUsContainer-content">

                <div className="aboutUsContainer-content__text">
                    <p className="title">{t("AboutUsHomeTitle")}</p>
                    <p className="text">{t("AboutUsHomeDescription")}</p>
                    <div className="betterButton-container">
                        <BetterButton link="/o-nas" label={t("AboutUsHomeMore")} className="betterButtonAbout"/>
                    </div>
                    <img src={blueCar} alt="blue car" className="blueCar"/>
                </div>

                <div className="aboutUsContainer-content-carousel">
                    <Carousel></Carousel>
                </div>
            </div>

            <div className="aboutUsContainer-squares">
                <div className="aboutUsContainer-squares__square">
                    <div className="aboutUsContainer-squares-textContainer">
                        <p className="aboutUsContainer-squares__mainText">{t("AboutUsHomeSquare1Main")}</p>
                        <p className="aboutUsContainer-squares__text">{t("AboutUsHomeSquare1Text")}</p>
                    </div>
                </div>
                <div className="aboutUsContainer-squares__square">
                    <div className="aboutUsContainer-squares-textContainer">
                        <p className="aboutUsContainer-squares__mainText">{t("AboutUsHomeSquare2Main")}</p>
                        <p className="aboutUsContainer-squares__text">{t("AboutUsHomeSquare2Text")}</p>
                    </div>
                </div>
                <div className="aboutUsContainer-squares__square">
                    <div className="aboutUsContainer-squares-textContainer">
                        <p className="aboutUsContainer-squares__mainText">{t("AboutUsHomeSquare3Main")}</p>
                        <p className="aboutUsContainer-squares__text">{t("AboutUsHomeSquare3Text")}</p>
                    </div>
                </div>
                <div className="aboutUsContainer-squares__square">
                    <div className="aboutUsContainer-squares-textContainer">
                        <p className="aboutUsContainer-squares__mainText">{t("AboutUsHomeSquare4Main")}</p>
                        <p className="aboutUsContainer-squares__text">{t("AboutUsHomeSquare4Text")}</p>
                    </div>
                </div>
            </div>
            <div className="aboutUsContainer-lines__bottom">
                <div className="aboutUsContainer-lines__blue"></div>
                <div className="aboutUsContainer-lines__red"></div>
                <div className="aboutUsContainer-lines__blue"></div>
            </div>
        </div>
    )
}

export default AboutUsHome;