import React from "react";
import "../../sass/carousel.css";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import star from '../../img/star.svg';
import {useTranslation} from "react-i18next";


const Carousel = () => {

    const {t} = useTranslation();

    const settings = {
        dots: false,
        infinite: true,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: false,
        speed: 500,

        autoplay: true,
        beforeChange: function (currentSlide, nextSlide) {
            // console.log("before change", currentSlide, nextSlide);
        },
        afterChange: function (currentSlide) {
            // console.log("after change", currentSlide);
        }
    };

    const slidesToShow = () => {
        if(window.matchMedia("(max-width: 476px").matches){
            return 2;
        }
        if(window.matchMedia("(max-width: 1200px) and (min-width: 769px) and (orientation: portrait)").matches) {
            return 2
        }
        if(window.matchMedia("(max-width: 1200px) and (min-width: 769px)").matches) {
            return 2
        }
        if(window.matchMedia("(max-width: 1600px) and (min-width: 1201px)").matches) {
            return 3;
        }
        else
            return 4;
    }
    settings.slidesToShow = slidesToShow();
    return (
        <div className="container">
            <Slider className="container-carousel" {...settings} >
                <div className="container-carousel-element odd">
                    <p>{t("CarouselReview1")}</p>
                    <div className="container-carousel-element-smallContainer">
                        <div className="container-carousel-element-smallContainer__name">
                            <div className="dot"></div>
                            <p className="authorName">Michał</p>
                        </div>
                        <div className="container-carousel-element-smallContainer__stars">
                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                        </div>
                    </div>
                </div>
                <div className="container-carousel-element">
                    <p>{t("CarouselReview2")}</p>
                    <div className="container-carousel-element-smallContainer">
                        <div className="container-carousel-element-smallContainer__name">
                            <div className="dot"></div>
                            <p className="authorName">Kolmax</p>
                        </div>
                        <div className="container-carousel-element-smallContainer__stars">

                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                        </div>
                    </div>
                </div>
                <div className="container-carousel-element odd">
                    <p>{t("CarouselReview3")}</p>
                    <div className="container-carousel-element-smallContainer">
                        <div className="container-carousel-element-smallContainer__name">
                            <div className="dot"></div>
                            <p className="authorName">Wojtek</p>
                        </div>
                        <div className="container-carousel-element-smallContainer__stars">

                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                        </div>
                    </div>
                </div>
                <div className="container-carousel-element">
                    <p>{t("CarouselReview4")}</p>

                    <div className="container-carousel-element-smallContainer">
                        <div className="container-carousel-element-smallContainer__name">
                            <div className="dot"></div>
                            <p className="authorName">Atlant</p>
                        </div>
                        <div className="container-carousel-element-smallContainer__stars">

                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                        </div>
                    </div>
                </div>
                <div className="container-carousel-element odd">
                    <p>{t("CarouselReview5")}</p>

                    <div className="container-carousel-element-smallContainer">
                        <div className="container-carousel-element-smallContainer__name">
                            <div className="dot"></div>
                            <p className="authorName">Cezary</p>
                        </div>
                        <div className="container-carousel-element-smallContainer__stars">

                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                        </div>
                    </div>
                </div>
                <div className="container-carousel-element">
                    <p>{t("CarouselReview6")}</p>

                    <div className="container-carousel-element-smallContainer">
                        <div className="container-carousel-element-smallContainer__name">
                            <div className="dot"></div>
                            <p className="authorName">Luki</p>
                        </div>
                        <div className="container-carousel-element-smallContainer__stars">

                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                        </div>
                    </div>
                </div>
                <div className="container-carousel-element odd">
                    <p>{t("CarouselReview7")}</p>
                    <div className="container-carousel-element-smallContainer">
                        <div className="container-carousel-element-smallContainer__name">
                            <div className="dot"></div>
                            <p className="authorName">Bartek</p>
                        </div>
                        <div className="container-carousel-element-smallContainer__stars">

                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                        </div>
                    </div>
                </div>
                <div className="container-carousel-element">
                    <p>{t("CarouselReview8")}</p>
                    <div className="container-carousel-element-smallContainer">
                        <div className="container-carousel-element-smallContainer__name">
                            <div className="dot"></div>
                            <p className="authorName">Maks</p>
                        </div>
                        <div className="container-carousel-element-smallContainer__stars">

                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                        </div>
                    </div>
                </div>
                <div className="container-carousel-element odd">
                    <p>{t("CarouselReview9")}</p>
                    <div className="container-carousel-element-smallContainer">
                        <div className="container-carousel-element-smallContainer__name">
                            <div className="dot"></div>
                            <p className="authorName">Monika</p>
                        </div>
                        <div className="container-carousel-element-smallContainer__stars">

                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                        </div>
                    </div>
                </div>
                <div className="container-carousel-element">
                    <p>{t("CarouselReview10")}</p>
                    <div className="container-carousel-element-smallContainer">
                        <div className="container-carousel-element-smallContainer__name">
                            <div className="dot"></div>
                            <p className="authorName">Bartosz</p>
                        </div>
                        <div className="container-carousel-element-smallContainer__stars">

                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                            <img src={star} alt="rating star"/>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    )
}
export default Carousel;
